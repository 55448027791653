.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.circular_button {
  border-radius: 50% !important;
}

.add_section_heading {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.add_section {
  background-color: white;
  padding: 16px 16px 0px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
