.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.small-upload .ant-upload.ant-upload-select-picture-card {
  height: 32px;
  margin: 0px;
}
.small-upload {
  height: 32px;
}
.add-section .ant-input,
.add-section .ant-input-number,
.add-section .ant-tag,
.add-section .ant-select-selector,
.add-section .ant-btn {
  border-radius: 6px !important;
}

.add-section {
  background-color: white;
  padding: 16px 16px 0px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
}

.add-section .ant-upload.ant-upload-select-picture-card {
  width: 100% !important;

  border-radius: 6px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
}

.add-section-heading {
  font-weight: bold;
}

.add-section .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px !important;
}

.add-section .type-space > .ant-space-item:first-child {
  width: 100% !important;
}

.add-section
  .ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding-top: 0px;
  padding-bottom: 0px;
}

.add-section .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0;
  padding-bottom: 8px;
}

.red-row {
  background-color: #fde7e7 !important;
}

.green-row {
  background-color: #d9f1d9 !important;
}
