body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

.ant-table th {
  transition: background-color 0.2s ease, border-color 0.2s ease;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bulk-edit-page .ant-table-cell {
  min-width: 150px;
}

.ant-table-cell.ant-table-selection-column.ant-table-cell-fix-left,
.ant-table-cell.ant-table-row-expand-icon-cell.ant-table-cell-fix-left {
  min-width: 0;
}

.ant-upload-list-picture-card-container {
  height: unset !important;
  max-height: 200px !important;
  width: unset !important;
  max-width: 200px !important;
}
